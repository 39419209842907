<template>
  <div>
    <v-dialog v-model="taksitDialog" eager max-width="1650" persistent>
      <v-card>
        <v-card-title>
          Taksit Seçenekleri
          <v-spacer />
          <v-btn @click="taksitDialog = false" icon color="rgb(73, 73, 73)">
            <v-icon>
              {{ icons.mdiWindowClose }}
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-tabs color="secondary" v-model="currentTab">
            <v-tab>Taksit Listesi</v-tab>
            <v-tab>Kayıt Ekle/Değiştir</v-tab>

            <v-tab-item>
              <v-divider class="mt-1"></v-divider>
              <v-card>
                <v-card-text>
                  <v-data-table
                    :headers="taksitHeaders"
                    :items="taksitItems"
                    :items-per-page="500"
                    :loading="loading"
                    hide-default-footer
                  >
                    <template #[`item.islemler`]="{ item }">
                      <v-tooltip bottom color="secondary">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            depressed
                            color="nightDark"
                            x-small
                            class="text-capitalize text-caption"
                            v-bind="attrs"
                            v-on="on"
                            @click="editTaksit(item)"
                          >
                            <v-icon small color="secondary">
                              {{ icons.mdiPencil }}
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Düzenle</span>
                      </v-tooltip>

                      <v-tooltip bottom color="error">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            depressed
                            color="error"
                            x-small
                            plain
                            v-bind="attrs"
                            @click="deleteTaksit(item)"
                            v-on="on"
                          >
                            <v-icon small>
                              {{ icons.mdiDelete }}
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Sil</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-divider class="mt-1"></v-divider>
              <v-card>
                <v-card-text>
                  <v-form ref="form">
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="3">
                          <label>Özel Kod</label>
                        </v-col>

                        <v-col cols="12" md="3">
                          <v-text-field v-model="kod" outlined dense></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="3">
                          <label>Taksit Sayısı</label>
                        </v-col>

                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="taksit_sayisi"
                            outlined
                            dense
                            hide-details="auto"
                            :rules="[required]"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="3">
                          <label>Taksit Açıklaması</label>
                        </v-col>

                        <v-col cols="12" md="3">
                          <v-text-field v-model="stext" outlined dense hide-details></v-text-field>
                          <div class="mt-1 text-caption">Örnek: +5 taksit</div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="3">
                          <label>Vade Oranı</label>
                        </v-col>

                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="vade_orani"
                            outlined
                            dense
                            hide-details="auto"
                            :rules="[required]"
                          ></v-text-field>
                          <div class="mt-1 text-caption">% (Yüzde değer)</div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="3">
                          <label>Sistem(ERP) Vade Oranı</label>
                        </v-col>

                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="sistem_vade_orani"
                            outlined
                            dense
                            hide-details="auto"
                            :rules="[required]"
                          ></v-text-field>
                          <div class="mt-1 text-caption">% (Yüzde değer)</div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="3">
                          <label>(Kendi Banka Kartları) Bloke Gün Sayısı</label>
                        </v-col>

                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="vade_gun_sayisi"
                            outlined
                            dense
                            hide-details="auto"
                            :rules="[required]"
                          ></v-text-field>
                          <div class="mt-1 text-caption">Blokeli gün sayısı</div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="3">
                          <label>(Diğer Banka Kartları) Bloke Gün Sayısı</label>
                        </v-col>

                        <v-col cols="12" md="3">
                          <v-text-field
                            v-model="db_vade_gun_sayisi"
                            outlined
                            dense
                            hide-details="auto"
                            :rules="[required]"
                          ></v-text-field>
                          <div class="mt-1 text-caption">Blokeli gün sayısı</div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="3">
                          <label>Kullanılacak Ödeme Kanalları</label>
                        </v-col>

                        <v-col cols="12" md="3">
                          <v-select
                            v-model="kanal"
                            dense
                            multiple
                            :items="odeme_kanallari_items"
                            label="Ödeme Kanalları"
                            outlined
                          ></v-select>
                          <div class="text-caption">Blokeli gün sayısı</div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-col offset-md="5" cols="12">
                      <v-btn :loading="loading" @click="kaydet()" color="success" class="white--text"
                        ><span v-if="taksitType == 'add'"> Kaydet ve Yeni Oluştur</span>
                        <span v-else> Güncelle</span>
                      </v-btn>
                    </v-col>
                  </v-form>
                  </v-card-text
                >
              </v-card>
            </v-tab-item>
          </v-tabs>
          x{{ currentTab }}x
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="taksitDialog = false" color="error">KAPAT</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card tile :flat="$vuetify.breakpoint.mdAndDown" :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg'">
      <v-toolbar
        v-if="!$vuetify.breakpoint.smAndDown"
        color="transparent"
        flat
        :height="$vuetify.breakpoint.smAndDown ? 57 : 87"
      >
        <v-toolbar-title class="text-h6 font-weight-medium">
          <v-icon large left>
            {{ icons.mdiPointOfSale }}
          </v-icon>
          Banka Tanımları
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn
         
          :class="{
            'bg-gradient-nightDark': !isDark,
            'bg-gradient-primary': isDark, // isDark ise farklı bir stil uygula
            'text-capitalize ms-3 rounded-l-pill rounded-r-0 mr-n4 white--text': true
          }"
          depressed
          x-large
          @click="addBank()"
          @mouseenter="activeHover = true" @mouseleave="activeHover = false"
        >
          <v-icon class="mr-2">{{ icons.mdiPlus }}</v-icon>
          <span>Banka Ekle</span>
          <span  v-if="activeHover"> <img src="@/assets/images/misc/tukas-yaprak.png" width="20" > </span>   
        </v-btn>
      </v-toolbar>

      <v-expansion-panels v-else v-model="panel" flat tile>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="text-body-2 font-weight-medium">
              <v-icon left>
                {{ icons.mdiPointOfSale }}
              </v-icon>
              Banka Tanımları
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pa-0">
            <v-divider></v-divider>
            <v-list class="pa-0" color="grey">
              <v-list-item @click="addBank()">
                <v-list-item-content>
                  <v-list-item-title>Banka Ekle</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>{{ icons.mdiPlusThick }}</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-divider></v-divider>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- table 
    :options.sync="options"
          :height="dataTableHeight"
      :server-items-length="totalAdminListTable"
        v-model="selectedRows"
    -->
      <v-data-table
        :headers="tableColumns"
        :items="dataListTable"
        :items-per-page="150"
        fixed-header
        :loading="loading"
      >
        <!-- Text 
     -->

        <template v-slot:[`item.banka_adi`]="{ item }">
          <v-text-field
            
            color="secondary"
            v-model="item.banka_adi"
            hide-details
            dense
            @change="menudenDegistir('id='+item.id,'banka_adi',item.banka_adi)"
            outlined
          ></v-text-field>
        </template>

        <template v-slot:[`item.kod`]="{ item }">
          <v-text-field
      
            color="secondary"
            v-model="item.kod"
            hide-details
            dense
            @change="menudenDegistir('id='+item.id,'kod',item.kod)"
            outlined
          ></v-text-field>
        </template>

        <template v-slot:[`item.ozel_kod`]="{ item }">
          <v-text-field
      
            color="secondary"
            v-model="item.ozel_kod"
            hide-details
            dense
            @change="menudenDegistir('id='+item.id,'ozel_kod',item.ozel_kod)"
            outlined
          ></v-text-field>
        </template>

        <template v-slot:[`item.aktif`]="{ item }">
          <v-checkbox
           
            color="secondary"
            v-model="item.aktif"
            hide-details
            dense
            @change="menudenDegistir('id='+item.id,'aktif',item.aktif == true ? (item.aktif = '1') : (item.aktif = '0'))"
          ></v-checkbox>
        </template>

        <template v-slot:[`item.pos_test_mode`]="{ item }">
          <v-checkbox
            
            color="secondary"
            v-model="item.pos_test_mode"
            hide-details
            dense
            @change="menudenDegistir('id='+item.id,'pos_test_mode',item.pos_test_mode == true ? (item.pos_test_mode = '1') : (item.pos_test_mode = '0'))"
          ></v-checkbox>
        </template>

        <template v-slot:[`item.varsayilan_tek_cekim`]="{ item }">
          <input type="radio" name="radioSelect" 
              :id=item.id  
              :value= item.varsayilan_tek_cekim  
              :v-model=item.varsayilan_tek_cekim 
              :checked="item.varsayilan_tek_cekim==true"          
              @change="menudenDegistir('id='+item.id,'varsayilan_tek_cekim',1)"
          />     
          </template>

        <template v-slot:[`item.sira`]="{ item }">
          <v-text-field
            color="secondary"
            v-model="item.sira"
            hide-details
            dense
            @change="menudenDegistir('id='+item.id,'sira',item.sira)"
            outlined
          ></v-text-field>
        </template>

        <template #[`item.actions`]="{ item }">
          <v-btn depressed color="nightDark" small class="text-capitalize text-caption" @click="editBank(item)">
            Düzenle
          </v-btn>

          <v-tooltip bottom color="warning">
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed color="warning" x-small plain v-bind="attrs" @click="copyBank(item)" v-on="on">
                <v-icon small>
                  {{ icons.mdiPlusBoxMultiple }}
                </v-icon>
              </v-btn>
            </template>
            <span>Kopyala</span>
          </v-tooltip>


          <v-tooltip bottom color="error">
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed color="error" x-small plain v-bind="attrs" @click="deleteBank(item)" v-on="on">
                <v-icon small>
                  {{ icons.mdiDelete }}
                </v-icon>
              </v-btn>
            </template>
            <span>Sil</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed color="accent" x-small plain v-bind="attrs" @click="openTaksit(item)" v-on="on">
                <v-icon small>
                  {{ icons.mdiFormatListCheckbox }}
                </v-icon>
              </v-btn>
            </template>
            <span>Taksit</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import router from '@/router'
import store from '@/store'
import { required } from '@core/utils/validation'
import {
  mdiDelete,
  mdiFormatListCheckbox,
  mdiPencil,
  mdiPlus,
  mdiPlusThick,
  mdiPointOfSale,
  mdiWindowClose,
  mdiPlusBoxMultiple,
} from '@mdi/js'
import { onMounted, ref, watch } from '@vue/composition-api'
import Vue from 'vue'
import useAppConfig from '@core/@app-config/useAppConfig'
export default {

  data() {
    return {
      activeHover : false
    }
  },
  setup() {
    const { isDark } = useAppConfig()
    const loading = ref(false)
    const dataListTable = ref([])
    const taksitDialog = ref(false)
    const currentTab = ref(0)
    const secilenTaksitID = ref('')
    const taksitItems = ref([])
    const taksitType = ref('add')
    const secilenTaksitDetayId = ref('')

    const kod = ref('')
    const taksit_sayisi = ref('')
    const stext = ref('')
    const vade_orani = ref('')
    const vade_gun_sayisi = ref('')
    const sistem_vade_orani = ref('')
    const db_vade_gun_sayisi = ref('')
    const kanal = ref('')

    const form = ref(null)

    const odeme_kanallari_items = [
      { text: 'Tahsilat Sistemi', value: 'MNL' },
      { text: 'Satış Sistemi', value: 'SAL' },
    ]

    const taksitHeaders = [
      {
        text: 'TID',
        value: 'id',
        sortable: false,
        width: '50px',
      },
      {
        text: 'Taksit Sayısı',
        value: 'taksit_sayisi',
        width: '50px',
      },
      {
        text: 'Açıklama',
        value: 'stext',
        width: '100px',
      },
      {
        text: 'Vade Oranı (%)',
        value: 'vade_orani',
        width: '50px',
      },
      {
        text: 'ERP Vade Oranı (%)',
        value: 'ozel_kod',
        width: '50px',
      },
      {
        text: 'Kendi Kartları İçin Blokeli Gün Sayısı',
        value: 'vade_gun_sayisi',
        width: '50px',
      },
      {
        text: 'Diğer Bankalar İçin Blokeli Gün Sayısı',
        value: 'db_vade_gun_sayisi',
        width: '50px',
      },
      {
        text: 'Kanal',
        value: 'kanal',
        width: '50px',
      },
      {
        text: 'Müşteri Grubu',
        value: 'banka_adi',
        width: '50px',
      },
      {
        text: 'Aktif',
        value: 'aktif',
        width: '50px',
      },
      {
        text: 'İşlemler',
        value: 'islemler',
        width: '250px',
      },
    ]

    const tableColumns = [
      {
        text: 'ID',
        value: 'id',
        sortable: false,
        width: '50px',
      },
      {
        text: 'Pos Türü',
        value: 'pos_turu',
        width: '50px',
      },
      {
        text: 'Kredi Kartı/Banka Adı',
        value: 'banka_adi',
        width: '200px',
      },

      {
        text: 'Banka Kodu',
        value: 'kod',
        width: '100px',
      },
      {
        text: 'ERP Kodu',
        value: 'ozel_kod',
        width: '100px',
      },
      {
        text: 'Aktif',
        value: 'aktif',
        width: '50px',
      },
      {
        text: 'Test Modu ?',
        value: 'pos_test_mode',
        width: '50px',
      },
      {
        text: 'Varsayılan Tek Çekim Hesabı?',
        value: 'varsayilan_tek_cekim',
        width: '50px',
      },
      {
        text: 'Sıra',
        value: 'sira',
        width: '100px',
      },
      {
        text: 'İşlemler',
        value: 'actions',
        align: 'left min-width-150',
        sortable: false,
        width: '220px',
      },
    ]

    onMounted(() => {
      loading.value = true
      fetchBanks()
    })

    watch(
      () => taksitDialog.value,
      () => {
        if (taksitDialog.value == false) {
          form.value.resetValidation()
          kod.value = ''
          taksit_sayisi.value = ''
          stext.value = ''
          vade_orani.value = ''
          sistem_vade_orani.value = ''
          vade_gun_sayisi.value = ''
          db_vade_gun_sayisi.value = ''
          kanal.value = ''
        }
      },
    )

    watch(
      () => currentTab.value,
      () => {
        if (currentTab.value == 0) {
          loading.value = true
          fetchTaksit()
        }
      },
    )

    const menudenDegistir_old = item => {
      item.aktif == true ? (item.aktif = '1') : (item.aktif = '0')
      item.pos_test_mode == true ? (item.pos_test_mode = '1') : (item.pos_test_mode = '0')
      store
        .dispatch('postMethod', {
          method: 'savePosDefination',
          id: item.id,
          'field[aktif]': item.aktif,
          'field[pos_test_mode]': item.pos_test_mode,
          'field[banka_adi]': item.banka_adi,
          'field[kod]': item.kod,
          'field[ent_kodu]': item.ent_kodu,
          'field[ozel_kod]': item.ozel_kod,
          'field[banka_postkey]': item.banka_postkey,
          'field[card_brand]': item.card_brand,
          'field[sira]': item.sira,
          'field[renk]': item.renk,
          'field[notlar]': item.notlar,
          'field[pos_turu]': item.pos_turu,
          'pos[prod][client_id]': item.pos_settings.prod.client_id,
          'pos[prod][terminal_no]': item.pos_settings.prod.terminal_no,
          'pos[prod][name]': item.pos_settings.prod.name,
          'pos[prod][pass]': item.pos_settings.prod.pass,
          'pos[prod][store_key]': item.pos_settings.prod.store_key,
          'pos[prod][api_url]': item.pos_settings.prod.api_url,
          'pos[prod][ucd_url]': item.pos_settings.prod.ucd_url,
          'pos[prod][mpi_url]': item.pos_settings.prod.mpi_url,
          'pos[prod][posnetid]': item.pos_settings.prod.posnetid,
          'pos[prod][keyxcip]': item.pos_settings.prod.keyxcip,
          'pos[prod][_3d_type]': item.pos_settings.prod._3d_type,
          'pos[test][client_id]': item.pos_settings.test.client_id,
          'pos[test][terminal_no]': item.pos_settings.test.terminal_no,
          'pos[test][name]': item.pos_settings.test.name,
          'pos[test][pass]': item.pos_settings.test.pass,
          'pos[test][store_key]': item.pos_settings.test.store_key,
          'pos[test][api_url]': item.pos_settings.test.api_url,
          'pos[test][ucd_url]': item.pos_settings.test.ucd_url,
          'pos[test][mpi_url]': item.pos_settings.test.mpi_url,
          'pos[test][posnetid]': item.pos_settings.test.posnetid,
          'pos[test][keyxcip]': item.pos_settings.test.keyxcip,
          'pos[test][_3d_type]': item.pos_settings.test._3d_type,
        })
        .then(response => {
          loading.value = true
          if (response.error == 1) {
            Vue.swal({
              title: 'Hata',
              html: response.msg,
              icon: 'error',
              background: '#FF4C51',
              timerProgressBar: true,
              showConfirmButton: false,
            })
          } else {
            store.commit('GIDEN_BANK', item)
            Vue.swal({
              title: 'Güncelleme Başarılı',
              //text: 'İşlem başarılı',
              icon: 'success',
              timer: 1500,
              background: '#56CA00',
              timerProgressBar: true,
              showConfirmButton: false,
            })
            fetchBanks()
          }
        })
    }

    const menudenDegistir = (updateId,updateField,updateValue) => {
      
        store
        .dispatch('postMethod', {
          method: 'setFieldVar',
          operation : 'bankalar',
          field : updateField,
          whereCond1 : updateId,
          value : updateValue         
        })
        .then(response => {
          
          loading.value = true
          if (response.error == 1) {
            Vue.swal({
              title: 'Hata',
              html: response.msg,
              icon: 'error',
              background: '#FF4C51',
              timerProgressBar: true,
              showConfirmButton: false,
            })
          } else {
            //store.commit('GIDEN_BANK', item)
            
            Vue.swal({
              title: 'Güncelleme Başarılı',
              //text: 'İşlem başarılı',
              icon: 'success',
              timer: 1500,
              background: '#56CA00',
              timerProgressBar: true,
              showConfirmButton: false,
            })
            fetchBanks()
          }
        })
    }


    const deleteTaksit = item => {
      console.log('ana id = ' + secilenTaksitID.value)
      console.log('taksit id = ' + item.id)

      Vue.swal({
        title: 'Emin misin?',
        text: 'Silme işlemini onaylayın',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Onaylıyorum',
      }).then(result => {
        if (result.isConfirmed) {
          loading.value = true
          store
            .dispatch('postMethod', {
              method: 'taksitDelete',
              bid: secilenTaksitID.value,
              id: item.id,
            })
            .then(() => {
              Vue.swal({
                title: 'Silindi',
                text: 'İşlem başarılı',
                icon: 'success',
                timer: 1500,
                timerProgressBar: true,
                showConfirmButton: false,
              })
              fetchTaksit()
            })
        }
      })
    }

    const editTaksit = item => {
      taksitType.value = 'edit'
      currentTab.value = 1
      secilenTaksitDetayId.value = item.id

      kod.value = item.kod
      taksit_sayisi.value = item.taksit_sayisi
      stext.value = item.stext
      vade_orani.value = item.vade_orani
      sistem_vade_orani.value = item.sistem_vade_orani
      vade_gun_sayisi.value = item.vade_gun_sayisi
      db_vade_gun_sayisi.value = item.db_vade_gun_sayisi
      kanal.value = item.kanal
    }

    const fetchTaksit = () => {
      store
        .dispatch('postMethod', {
          method: 'getTaksitListesi',
          bid: secilenTaksitID.value,
        })
        .then(response => {
          console.log(response)
          taksitItems.value = response.detail
          loading.value = false
        })
    }

    const openTaksit = item => {
      taksitDialog.value = true
      currentTab.value = 0
      secilenTaksitID.value = item.id
      loading.value = true
      fetchTaksit()
    }

    const kaydet = () => {
      form.value.validate()
      console.log(secilenTaksitID.value)
      if (form.value.validate() == false) {
        Vue.swal({
          title: 'Hata',
          html: 'Lütfen zorunlu alanları doldurunuz',
          icon: 'error',
          background: '#FF4C51',
          timerProgressBar: true,
          showConfirmButton: true,
        })
      } else {
        store
          .dispatch('postMethod', {
            method: 'taksitSave',
            id: taksitType.value == 'edit' ? secilenTaksitDetayId.value : '',
            bid: secilenTaksitID.value,
            'field[kod]': kod.value,
            'field[taksit_sayisi]': taksit_sayisi.value,
            'field[stext]': stext.value,
            'field[vade_orani]': vade_orani.value,
            'field[sistem_vade_orani]': sistem_vade_orani.value,
            'field[vade_gun_sayisi]': vade_gun_sayisi.value,
            'field[db_vade_gun_sayisi]': db_vade_gun_sayisi.value,
            'field[kanal]': kanal.value,
          })
          .then(response => {
            console.log(response)
            if (response.error == 1) {
              Vue.swal({
                title: 'Hata',
                html: response.msg,
                icon: 'error',
                background: '#FF4C51',
                timerProgressBar: true,
                showConfirmButton: false,
              })
            } else {
              Vue.swal({
                title: 'Kayıt Başarılı',
                icon: 'success',
                timer: 2000,
                background: '#56CA00',
                timerProgressBar: true,
                showConfirmButton: false,
              })
            }

            form.value.resetValidation()

            kod.value = ''
            taksit_sayisi.value = ''
            stext.value = ''
            vade_orani.value = ''
            sistem_vade_orani.value = ''
            vade_gun_sayisi.value = ''
            db_vade_gun_sayisi.value = ''
            kanal.value = ''

            currentTab.value = 0
          })
      }
    }

    const addBank = () => {
      router.push({ name: 'pos-tanimlari-ekle' })
    }

    const fetchBanks = () => {
      store
        .dispatch('postMethod', {
          method: 'getPosDefinations',
        })

        .then(response => {
          console.log(response)
          response.response.result.forEach(item => {
            item.aktif == '1' ? (item.aktif = true) : (item.aktif = false)
            item.pos_test_mode == '1' ? (item.pos_test_mode = true) : (item.pos_test_mode = false)
            item.varsayilan_tek_cekim == '1' ? (item.varsayilan_tek_cekim = true) : (item.varsayilan_tek_cekim = false)
          })
          if (response.error == 1) {
            Vue.swal({
              title: 'Hata',
              html: response.msg,
              icon: 'error',
              background: '#FF4C51',
              timerProgressBar: true,
              showConfirmButton: false,
            })
            loading.value = false
          } else {
            dataListTable.value = response.response.result
            loading.value = false
          }
        })
    }

    const editBank = item => {
      console.log(item.id)
      store.commit('GIDEN_BANK', item)
      router.push({ path: '/pos-tanimlari-duzenle/' + item.id })
    }

    const copyBank = item => {
      console.log(item.id)
      store.commit('GIDEN_BANK', item)
      router.push({ path: `/pos-tanimlari-duzenle/${item.id}/true` })
    }

    const deleteBank = item => {
      Vue.swal({
        title: 'Emin misin?',
        text: 'Silme işlemini onaylayın',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Onaylıyorum',
      }).then(result => {
        if (result.isConfirmed) {
          loading.value = true
          store
            .dispatch('postMethod', {
              method: 'deletePosDefination',
              id: item.id,
            })
            .then(() => {
              Vue.swal({
                title: 'Silindi',
                text: 'İşlem başarılı',
                icon: 'success',
                timer: 1500,
                timerProgressBar: true,
                showConfirmButton: false,
              })
              fetchBanks()
            })
        }
      })
    }

    return {
      kod,
      taksit_sayisi,
      stext,
      vade_orani,
      sistem_vade_orani,
      vade_gun_sayisi,
      db_vade_gun_sayisi,
      kanal,
      taksitDialog,
      secilenTaksitDetayId,
      menudenDegistir,
      tableColumns,
      dataListTable,
      loading,
      form,
      taksitItems,
      secilenTaksitID,
      currentTab,
      taksitHeaders,
      odeme_kanallari_items,
      taksitType,
      deleteTaksit,
      editTaksit,
      required,
      kaydet,
      openTaksit,
      editBank,
      fetchBanks,
      deleteBank,
      addBank,
      fetchTaksit,
      copyBank,
      isDark,
      icons: {
        mdiPencil,
        mdiFormatListCheckbox,
        mdiPlusThick,
        mdiPlus,
        mdiWindowClose,
        mdiDelete,
        mdiPointOfSale,
        mdiPlusBoxMultiple,
      },
    }
  },
}
</script>

<style>
.v-application .primary--text {
  color: var(--v-secondary-base) !important;
  caret-color: var(--v-secondary-base) !important;
}
</style>
